<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'solicitors' }">{{$t('label_solicitors')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{ action == 'editing'? $t('label_edit_solicitor') : $t('label_add_solicitor') }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card
                    no-body
                    class="mb-0"
            >
                <!-- Header -->
                <div class="d-flex  mt-1 px-2 py-1">
                    <h5 class="mb-0">
                        {{ action == 'editing'? $t('label_edit_solicitor') : $t('label_add_solicitor') }}
                    </h5>
                </div>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >
                    <!-- Username -->
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_first_name')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_first_name')"
                                class="required"
                        >
                            <b-form-input

                                    v-model="itemData.firstName"
                                    :placeholder="$t('label_first_name')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_last_name')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_last_name')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.lastName"
                                    :placeholder="$t('label_last_name')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_phone')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_phone')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.phone"
                                    :placeholder="$t('label_phone')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_name_of_user')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_name_of_user')"
                                class="required"
                        >
                            <!--:readonly="(action == 'editing') ? true : false"-->
                            <b-form-input
                                    v-model="itemData.userName"
                                    :placeholder="$t('label_name_of_user')"
                                    :state="getValidationState(validationProps)"
                                    trim

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('Email')"
                            rules="required|email"
                            slim
                    >
                        <b-form-group
                                :label="$t('Email')"
                                class="required"
                        >
                            <!--:readonly="(action == 'editing') ? true : false"-->
                            <b-form-input
                                    v-model="itemData.emailAddress"
                                    :placeholder="$t('Email')"
                                    :state="getValidationState(validationProps)"
                                    trim

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_password')"
                            :rules="action == 'adding' ? 'required' : ''"
                            slim
                    >
                        <b-form-group

                                :label="$t('label_password')"
                                :class="action == 'adding' ? 'required' : ''"
                        >
                            <b-form-input
                                    autocomplete="off"
                                    v-model="itemData.userPassword"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_password')"
                                    type="password"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_confirm_password')"
                            :rules="action == 'adding' ? 'required' : ''"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_confirm_password')"
                                :class="action == 'adding' ? 'required' : ''"
                        >
                            <b-form-input
                                    v-model="itemData.cPassword"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_confirm_password')"
                                    type="password"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_department')"
                            rules="required"
                            slim
                    >

                        <b-form-group
                                :label="$t('label_department')"
                                :state="getValidationState(validationProps)"
                        >
                            <div  class="d-flex align-items-center flex-wrap flex-md-nowrap">

                                <v-select
                                        class="form-control mb-1 mr-md-1"
                                        v-model="itemData.department_id"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="department_id"
                                        :clearable="false"
                                        :reduce="val => val.id"
                                        :placeholder="$t('label_select')"
                                        :options="departments"
                                >
                                    <template v-slot:option="option">
                                        {{option.name}}
                                    </template>
                                    <template v-slot:selected-option="option">
                                        {{option.name}}
                                    </template>
                                    <template v-slot:no-options="option">
                                        {{$t('label_no_items')}}
                                    </template>
                                </v-select>

                                    <b-button @click="$bvModal.show('department-modal')" class=" mb-md-1" style=" white-space: nowrap;" size="sm" variant="outline-primary">{{$t('label_add') + ' ' + $t('label_department').toLowerCase()}}</b-button>

                            </div>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_position')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_position')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.position"
                                    :placeholder="$t('label_position')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_country')"
                            rules="required"
                            slim
                    >
                        <b-form-group :label="$t('label_country')"
                        >

                            <v-select
                                    v-model="itemData.country_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="countries"
                                    :reduce="val => val.idCountry"
                                    :searchable="true"
                                    label="countryName"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                            >
                                <template v-slot:option="option">
                                    {{option.countryName}}
                                </template>
                                <template v-slot:selected-option="option">
                                    {{option.countryName}}
                                </template>
                                <template v-slot:no-options="option">
                                    {{$t('label_no_items')}}
                                </template>
                            </v-select>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_city')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_city')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.city"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_city')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_company_name')"

                            slim
                    >
                        <b-form-group
                                :label="$t('label_company_name')"

                        >
                            <b-form-input
                                    v-model="itemData.company_name"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_company_name')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_nip')"
                            rules=""
                            slim
                    >
                        <b-form-group
                                :label="$t('label_nip')"
                                class=""
                        >
                            <b-form-input
                                    v-model="itemData.NIP"
                                    trim
                                    :placeholder="$t('label_nip')"
                                    :state="getValidationState(validationProps)"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_hourly_rate')"
                            rules="numeric"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_hourly_rate')"

                        >
                            <b-form-input
                                    v-model="itemData.hourly_rate"
                                    trim
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_hourly_rate')"

                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <div class="permissions-block mt-2 mb-2">

                        <p>{{$t('label_permissions')}}</p>

                            <b-form-group v-for="perm in userPermissions"
                                    label="" class="mt-1"
                            >
                                <b-form-checkbox
                                        :id="perm.alias"
                                        value="1"
                                        unchecked-value="0"
                                        v-model="itemData[perm.alias]"
                                >
                                    <label :for="perm.alias">
                                            {{(perm.label.length == 1)? $t(perm.label[0]) : ($t(perm.label[0]) + ' (' + $t(perm.label[1]) + ')')}}
                                    </label>
                                </b-form-checkbox>
                            </b-form-group>

                    </div>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_language')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_language')"
                                class="required"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select
                                    v-model="itemData.language"
                                    :reduce="val => val.id"
                                    input-id="language"
                                    label="language"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                                    :options="languages"
                            >
                                <template v-slot:option="option">
                                    {{option.title}}
                                </template>
                                <template v-slot:selected-option="option">
                                    {{option.title}}
                                </template>
                                <template v-slot:no-options="option">
                                    {{$t('label_no_items')}}
                                </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_save') : $t('label_add') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
        <department-modal  @item-added="departments.unshift($event); itemData.department_id = $event.id; consoleLog('adedde',$event,departments)"></department-modal>
    </div>
</template>

<script>

    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import departmentModal from './../department/modals/processItemModal'
    import {
        BSidebar,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton, BCard, BFormTextarea, BFormCheckbox,
        BInputGroup,

    } from 'bootstrap-vue'

    import {MODULE_PREFIX as PREFIX, DEPARTMENT_PREFIX, LANGUAGES_PREFIX} from './moduleHelper'
    import {userPermissions, defaultCountryId, defaultLanguageId} from './../pagesHelper'
    import vSelect from 'vue-select'
    import  infinityScroll from '@/views/components/infinityScroll'

    export default {
        name: "editItem.vue",
        components: {
            BSidebar,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            BFormCheckbox,
            BFormTextarea,
            BInputGroup,
            ValidationProvider,
            ValidationObserver,
            infinityScroll,
            departmentModal
        },
        data() {
            return {

                DEPARTMENT_PREFIX,
                LANGUAGES_PREFIX,
                PREFIX,

                itemData: {},

                action: 'adding',

                blankItemData: {

                    id:null,
                    firstName: '',
                    lastName: '',
                    userName: '',
                    phone: '',
                    emailAddress: '',
                    userPassword: '',
                    cPassword: '',
                    department_id: null,
                    parent_id: null,
                    country_id: defaultCountryId,
                    city: '',
                    company_name: '',
                    NIP: '',
                    position: '',
                    language: defaultLanguageId,
                    hourly_rate: ''

                },
                userPermissions,
                countries: [],
                departments: [],
                languages: [],
            }
        },
        created() {

            this.blankItemData.id = this.$router.currentRoute.params.id;

            for(let i =0; i < userPermissions.length; i++) {
                this.blankItemData[userPermissions[i].alias] = 0;
            }

            this.blankItemData.id = this.$router.currentRoute.params.id;

            this.initData();

        },
        methods: {

            initData() {

                if (this.blankItemData.id > 0) {
                    this.action = 'editing';
                    this.async('get', '/' + this.PREFIX + '/' + this.blankItemData.id, {}, function (resp) {

                        let editedItem = resp.data;
                        let item = Object.assign({}, this.blankItemData);
                        item.id = editedItem.id;
                        for (let prop in item) {
                            if (editedItem.hasOwnProperty(prop)) {
                                item[prop] = editedItem[prop];
                            }
                        }
                        for (let prop in item) {
                            if (prop != 'id' && editedItem.solicitor_data.hasOwnProperty(prop)) {
                                item[prop] = editedItem.solicitor_data[prop];
                            }
                        }
                        // if(parseInt(editedItem.parent_id) > 0 ) {
                        //     item.solicitor_employee = 'Y';
                        // }
                        item.userPassword = '';
                        item.cPassword = '';
                        this.itemData = item;
                        console.log('zxc', item);
                    });
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);
                }

                this.async('get', '/countries', {}, (res) => {this.countries = res.data});

                this.async('get', '/' + this.DEPARTMENT_PREFIX + 's', {}, function (resp) {
                    this.departments = resp.data.departments;
                });
                this.async('get', '/' + this.LANGUAGES_PREFIX, {}, function (resp) {
                    // console.log(resp);
                    this.languages = resp.data;
                });
            },
            onSubmit() {
                if (this.action == 'adding') {
                    this.async('post', '/' + this.PREFIX, this.itemData, function (resp) {

                        this.$router.push({name: 'solicitor-details', params:{id: resp.data.item.id}});

                    });
                } else {

                    this.async('put', '/' + this.PREFIX + '/' + this.itemData.id, this.itemData, function (resp) {
                        // this.$emit('item-edited',{});
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                        this.$router.push({name: 'solicitor-details', params:{id: this.itemData.id}});
                    });
                }
            },

        }
    }
</script>

<!--<style scoped>-->

<!--</style>-->